// ---
// The following is needed because links contained (NextLink) have weird parameters (in the opposite of a button with "onClick") that breaks the wanted UI
// Adding here some workarounds but more may be needed depending on the color needed (I was not able to override or disable the style making a mess... tried a lot)
//
// For example, those are responsible:
// * --hover-tint: var(--idle);
// * --active-tint: var(--active);
.MuiButton-containedPrimary {
  background-image: none !important;

  &:hover {
    background-color: var(--background-active-blue-france-hover) !important;
  }

  &:active {
    background-color: var(--background-active-blue-france-active) !important;
  }
}

a.MuiMenuItem-root {
  background-image: none !important;

  &:hover {
    background-color: var(--background-default-grey-hover) !important;
  }

  &:active {
    background-color: var(--background-default-grey-active) !important;
  }
}

.MuiTablePagination-root {
  & > .MuiToolbar-root {
    justify-content: end;
    flex-wrap: wrap; // Required otherwise on mobile it stays on 1 line with a scrollbar (tried dealing with max-width but nothing to do)

    & > p {
      // For whatever reason the text elements stick to the top otherwise (maybe a DSFR style leak?)
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
